// --
// Dependencies
import { useState } from 'react';


// --
// JoinWaitlistForm Component
const JoinWaitlistForm = () => {
    const [ email, setEmail ] = useState( '' );


    // Handle Submit
    const handleSubmit = () => {
        alert( email );
    }


    // Render Component
    return (
        <div className="waitlist-form mt-2">
            <div className="input-wrapper display-flex flex-row align-items-center">
                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    viewBox="0 0 512 512"
                    width="25px"
                >
                    <title>
                        Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.
                    </title>
                    
                    <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                </svg>

                <input 
                    type="email" 
                    placeholder="Your email"
                    className="font-body"
                    value={ email }
                    onChange={( event ) => { setEmail( event.target.value )}}
                    tabIndex={ 0 }
                />
            </div>

            <div className="input-wrapper display-flex flex-row align-items-center">
                <button 
                    className="display-flex flex-row align-items-center justify-content-between font-body-bold" 
                    onClick={ handleSubmit }
                    tabIndex={ 0 }
                >
                    Join the waitlist

                    <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 448 512" 
                        width="20px"
                    >
                        <title>
                            Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.
                        </title>
                        
                        <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" />
                    </svg>
                </button>
            </div>
        </div>
    );
}


// -- 
// Export
export default JoinWaitlistForm;