// --
// Child Components
import JoinWaitlistForm from "../elements/JoinWaitlistForm";


// --
// Intro Section Component
const Intro = () => {

    const showForm = false;


    // Render Personas
    const renderPersonas = () => {
        const numbers = [ '1', '2', '3', '4', '5' ];

        return numbers.map(( number, index ) => {
            return (
                <img 
                    key={ index }
                    src={ `/images/persona-${ number }.jpg` }
                    alt="AimlessHues Persona Avatar"
                />
            );
        });
    }


    // Main Render Component
    return (
        <div 
            id="Intro"
            className="width-full display-flex flex-column justify-content-center align-items-center"
        >
            <div 
                id="Intro-bg-overlay" 
                className="width-full display-flex flex-column align-items-center justify-content-center"
            >
                <div id="Intro-personas">
                    { renderPersonas() }
                </div>

                <img 
                    src="/images/ah-palette-logo.svg"
                    width={ 200 }
                    alt="AimlessHues Logo"
                    className="mb-1"
                />

                <h1 className="font-body-bold mt-3 mb-0">
                    The key to your color enlightenment.
                </h1>

                <h2 className="font-body mt-1 mb-0">
                    AimlessHues is not just another color palette library. 
                </h2>

                <h2 className="font-body mt-0">
                    It gives you the tools to create, configure and visualize your branding vision with ease and speed.
                </h2>

                {
                    showForm ?
                        <JoinWaitlistForm />
                    :
                        <p className="font-body">
                            More information and wait list coming soon...
                        </p>
                }
            </div>

        </div>
    );
}


// --
// Export
export default Intro;