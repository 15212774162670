// --
// Child Components
import Intro from './sections/Intro';


const App = () => {
  return (
    <div className="App width-full p-0 m-0">
      <Intro />
    </div>
  );
}

export default App;
